import React, { useState, useEffect, createContext } from 'react';

export const ConsentContext = createContext();

const ConsentProvider = ({ children }) => {
    const defaultState = false;
    const [_consent, _setConsent] = useState(defaultState);

    const giveConsent = () => {
        _setConsent({ _consent: true });
        localStorage.setItem('consent', 'true');
    }

    useEffect(() => {
        const consentGiven = JSON.parse(localStorage.getItem('consent'));

        if (consentGiven) {
            _setConsent({ _consent: true });
        }
    }, []);

    return (
        <ConsentContext.Provider value={{ _consent, giveConsent }}>
            { children }
        </ConsentContext.Provider>
    )
}

export default ConsentProvider;